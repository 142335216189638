export const environment = {
  // API: 'http://surveyapp.knowledgelens.com/momentZS_v1.9/',
  // http://momentzs-dev.knowledgelens.com/test_v4.0/
  // API: 'http://momentzs-dev.knowledgelens.com/test_v4.0/',
  // API: 'https://surveyapp.knowledgelens.com/momentZS_v2.0/',
  // API: 'https://api.mzs-ci.dev.zsservices.com/',
  // API: 'https://api.mzs-ci.zsservices.com/',
  API: 'https://mzs.qa.zsservices.com/services/',
  REPORTAPI: 'https://mzs.qa.zsservices.com/reports/',
  // API: 'https://surveyapp.knowledgelens.com/momentZS_v2.0/',
  firebase: {
    apiKey: "AIzaSyCIhBF22vOxrpFxReN1eo8xNGQcF-XlmqY",
    authDomain: "g0248-zs-momentzs-qa-app.firebaseapp.com",
    databaseURL: "https://g0248-zs-momentzs-qa-app-default-rtdb.firebaseio.com",
    projectId: "g0248-zs-momentzs-qa-app",
    storageBucket: "g0248-zs-momentzs-qa-app.appspot.com",
    messagingSenderId: "951380465475",
    appId: "1:951380465475:web:5d4155e8f4897fd065c1f6",
    measurementId: "G-SLVJ3V5JB1"
  },
  production: true
};
